<template>
    <div class="content-wrapper">
        <bo-page-title />

        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No SEP</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpNoSEP" class="form-control" 
                            placeholder="Ketik No SEP" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchNoSEP" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetNoSEP">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwNoSEP">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No SEP</h4>
                                            <p>{{dataNoSEP.noSep||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tanggal SEP</h4>
                                            <p>{{dataNoSEP.tglSep||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Jenis Pelayanan</h4>
                                            <p>{{dataNoSEP.jnsPelayanan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Poli</h4>
                                            <p>{{dataNoSEP.poli||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Diagnosa</h4>
                                            <p>{{dataNoSEP.diagnosa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Peserta</h4>
                                            <p>Nomor Kartu : {{dataNoSEP.peserta.noKartu||"-"}}</p>
                                            <p>Nama: {{dataNoSEP.peserta.nama||"-"}}</p>
                                            <p>Tanggal Lahir : {{dataNoSEP.peserta.tglLahir||"-"}}</p>
                                            <p>Kelamin : {{dataNoSEP.peserta.kelamin||"-"}}</p>
                                            <p>Nomor Kartu : {{dataNoSEP.peserta.hakKelas||"-"}}</p>     
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Provinsi Umum</h4>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kode Provider</h4>
                                            <p>{{dataNoSEP.provUmum.kdProvider||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Provider</h4>
                                            <p>{{dataNoSEP.provUmum.nmProvider||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Provinsi Kode Perujuk</h4>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kode Perujuk</h4>
                                            <p>{{dataNoSEP.provPerujuk.kdProviderPerujuk||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Provider</h4>
                                            <p>{{dataNoSEP.provPerujuk.nmProviderPerujuk||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Asal Rujukan</h4>
                                            <p>{{dataNoSEP.provPerujuk.asalRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No Rujukan</h4>
                                            <p>{{dataNoSEP.provPerujuk.noRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tanggal Rujukan</h4>
                                            <p>{{dataNoSEP.provPerujuk.tglRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Surat Kontrol</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpSuratKontrol" class="form-control" 
                            placeholder="Ketik No Surat Kontrol" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchSuratKontrol" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetSuratKontrol">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwSuratKontrol">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>flagKontrol</h4>
                                            <p>{{dataSuratKontrol.flagKontrol||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jnsKontrol</h4>
                                            <p>{{dataSuratKontrol.jnsKontrol||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kodeDokter</h4>
                                            <p>{{dataSuratKontrol.kodeDokter||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>kodeDokterPembuat</h4>
                                            <p>{{dataSuratKontrol.kodeDokterPembuat||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>namaDokter</h4>
                                            <p>{{dataSuratKontrol.namaDokter||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>namaDokterPembuat</h4>
                                            <p>{{dataSuratKontrol.namaDokterPembuat||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>namaJnsKontrol</h4>
                                            <p>{{dataSuratKontrol.namaJnsKontrol||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>namaPoliTujuan</h4>
                                            <p>{{dataSuratKontrol.namaPoliTujuan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noSuratKontrol</h4>
                                            <p>{{dataSuratKontrol.noSuratKontrol||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliTujuan</h4>
                                            <p>{{dataSuratKontrol.poliTujuan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglRencanaKontrol</h4>
                                            <p>{{dataSuratKontrol.tglRencanaKontrol||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTerbit</h4>
                                            <p>{{dataSuratKontrol.tglTerbit||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Data SEP</h4>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No SEP</h4>
                                            <p>{{dataSuratKontrol.sep.noSep||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tanggal SEP</h4>
                                            <p>{{dataSuratKontrol.sep.tglSep||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Jenis Pelayanan</h4>
                                            <p>{{dataSuratKontrol.sep.jnsPelayanan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Poli</h4>
                                            <p>{{dataSuratKontrol.sep.poli||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Diagnosa</h4>
                                            <p>{{dataSuratKontrol.sep.diagnosa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Peserta</h4>
                                            <p>Nomor Kartu : {{dataSuratKontrol.sep.peserta.noKartu||"-"}}</p>
                                            <p>Nama: {{dataSuratKontrol.sep.peserta.nama||"-"}}</p>
                                            <p>Tanggal Lahir : {{dataSuratKontrol.sep.peserta.tglLahir||"-"}}</p>
                                            <p>Kelamin : {{dataSuratKontrol.sep.peserta.kelamin||"-"}}</p>
                                            <p>Nomor Kartu : {{dataSuratKontrol.sep.peserta.hakKelas||"-"}}</p>     
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Provinsi Umum</h4>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kode Provider</h4>
                                            <p>{{dataSuratKontrol.sep.provUmum.kdProvider||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Provider</h4>
                                            <p>{{dataSuratKontrol.sep.provUmum.nmProvider||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                                <h4>Provinsi Kode Perujuk</h4>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kode Perujuk</h4>
                                            <p>{{dataSuratKontrol.sep.provPerujuk.kdProviderPerujuk||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama Provider</h4>
                                            <p>{{dataSuratKontrol.sep.provPerujuk.nmProviderPerujuk||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Asal Rujukan</h4>
                                            <p>{{dataSuratKontrol.sep.provPerujuk.asalRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No Rujukan</h4>
                                            <p>{{dataSuratKontrol.sep.provPerujuk.noRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tanggal Rujukan</h4>
                                            <p>{{dataSuratKontrol.sep.provPerujuk.tglRujukan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Surat Kontrol</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-12" id="formOptPenunjang">
                            <div class="row gx-1">
                            <div class="col-md-3">
                                <div class="form-group mb-0">
                                <input v-model="inpSuratKontrolNoKa" class="form-control" 
                                placeholder="Ketik No Surat Kontrol" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group mb-0">
                                <input v-model="BulanSuratKontrolNoKa" class="form-control" 
                                placeholder="Ketik Bulan" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group mb-0">
                                <input v-model="TahunSuratKontrolNoKa" class="form-control" 
                                placeholder="Ketik Tahun" />
                                </div>
                            </div>
                            <div class="col-md-2">
                                <v-select placeholder="Pilih Jenis Faskes" v-model="FormatSuratKontrolNoKa" :options="Config.mr.jenisFormat" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                            
                            <div class="col-md-auto">
                                <button @click="searchSuratKontrolNoKa" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetSuratKontrolNoKa">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwSuratKontrolNoKa">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>jnsKontrol</th>
                                    <th>jnsPelayanan</th>
                                    <th>kodeDokter</th>
                                    <th>nama</th>
                                    <th>namaDokter</th>
                                    <th>namaJnsKontrol</th>
                                    <th>namaPoliAsal</th>
                                    <th>namaPoliTujuan</th>
                                    <th>noKartu</th>
                                    <th>noSepAsalKontrol</th>
                                    <th>noSuratKontrol</th>
                                    <th>poliAsal</th>
                                    <th>poliTujuan</th>
                                    <th>terbitSEP</th>
                                    <th>tglRencanaKontrol</th>
                                    <th>tglSEP</th>
                                    <th>tglTerbitKontrol</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataSuratKontrolNoKa.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <span>{{v.jnsKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.jnsPelayanan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kodeDokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nama||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaDokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaJnsKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaPoliAsal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaPoliTujuan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noKartu||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSepAsalKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSuratKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.poliAsal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.poliTujuan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.terbitSEP||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglRencanaKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglSEP||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglTerbitKontrol||"-"}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Data Kontrol</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-12" id="formOptPenunjang">
                            <div class="row gx-1">
                            
                            <div class="col-md-3">
                                <v-select placeholder="Pilih Data Kontrol" v-model="inpDataKontrol" :options="Config.mr.jenisFormat" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>

                            <div class="col-md-4">
                                <div class="input-group">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="datePickerConfig.locale"
                                        :autoApply="datePickerConfig.autoApply"
                                        v-model="dateRangeDataKontrol"
                                        :opens="'right'"
                                        :ranges="datePickerConfig.ranges"
                                    >
                                        <template v-slot:input="picker">
                                            <template v-if="picker.startDate && picker.endDate">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                            <template v-else>
                                                Pilih Tanggal
                                            </template>
                                        </template>
                                    </date-range-picker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-auto">
                                <button @click="searchDataKontrol" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetDataKontrol">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwDataKontrol">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>jnsKontrol</th>
                                    <th>jnsPelayanan</th>
                                    <th>kodeDokter</th>
                                    <th>nama</th>
                                    <th>namaDokter</th>
                                    <th>namaJnsKontrol</th>
                                    <th>namaPoliAsal</th>
                                    <th>namaPoliTujuan</th>
                                    <th>noKartu</th>
                                    <th>noSepAsalKontrol</th>
                                    <th>noSuratKontrol</th>
                                    <th>poliAsal</th>
                                    <th>poliTujuan</th>
                                    <th>terbitSEP</th>
                                    <th>tglRencanaKontrol</th>
                                    <th>tglSEP</th>
                                    <th>tglTerbitKontrol</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataDataKontrol.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>
                                            <span>{{v.jnsKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.jnsPelayanan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.kodeDokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.nama||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaDokter||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaJnsKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaPoliAsal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.namaPoliTujuan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noKartu||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSepAsalKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.noSuratKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.poliAsal||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.poliTujuan||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.terbitSEP||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglRencanaKontrol||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglSEP||"-"}}</span>
                                        </td>
                                        <td>
                                            <span>{{v.tglTerbitKontrol||"-"}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Poli/Spesialistik</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-12" id="formOptPenunjang">
                            <div class="row gx-1">
                            
                            <div class="col-md-3">
                                <div class="form-group mb-0">
                                <input v-model="inpPoli" class="form-control" 
                                placeholder="Ketik Nomor" />
                                </div>
                            </div>

                            <div class="col-md-3">
                                <v-select placeholder="Pilih Jenis Kontrol" v-model="jenisPoli" :options="Config.mr.jenisKontrol" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent"
                                    placeholder="Pilih Tanggal" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar" v-model="tanggalPoli">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-auto">
                                <button @click="searchPoli" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetPoli">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwPoli">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>kodePoli</th>
                                    <th>namaPoli</th>
                                    <th>kapasitas</th>
                                    <th>jmlRencanaKontroldanRujukan</th>
                                    <th>persentase</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataPoli.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>{{v.kodePoli||"-"}}</td>
                                        <td>{{v.namaPoli||"-"}}</td>
                                        <td>{{v.kapasitas||"-"}}</td>
                                        <td>{{v.jmlRencanaKontroldanRujukan||"-"}}</td>
                                        <td>{{v.persentase||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Dokter</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-lg-12" id="formOptPenunjang">
                            <div class="row gx-1">
                            
                            <div class="col-md-3">
                                <div class="form-group mb-0">
                                <input v-model="inpDokter" class="form-control" 
                                placeholder="Ketik Nomor Poli" />
                                </div>
                            </div>

                            <div class="col-md-3">
                                <v-select placeholder="Pilih Jenis Kontrol" v-model="jenisDokter" :options="Config.mr.jenisKontrol" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                            
                            <div class="col-md-3">
                                <div class="input-group mb-3">
                                    <datepicker input-class="form-control transparent"
                                    placeholder="Pilih Tanggal" class="my-datepicker"
                                    calendar-class="my-datepicker_calendar" v-model="tanggalDokter">
                                    </datepicker>
                                    <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-auto">
                                <button @click="searchDokter" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                    class="icon-search4"></i></b>Cari</button>
                                <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetDokter">Reset</button>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2" v-if="shwDokter">
                        <div class="col-lg-12 table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>kodeDokter</th>
                                    <th>namaDokter</th>
                                    <th>jadwalPraktek</th>
                                    <th>kapasitas</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in dataDokter.list" :key="v.kode">
                                        <td>{{k+1}}</td>
                                        <td>{{v.kodeDokter||"-"}}</td>
                                        <td>{{v.namaDokter||"-"}}</td>
                                        <td>{{v.jadwalPraktek||"-"}}</td>
                                        <td>{{v.kapasitas||"-"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
        inpNoSEP:"",
        shwNoSEP: false,  
        dataNoSEP: {},
        
        inpSuratKontrol:"",
        shwSuratKontrol: false,  
        dataSuratKontrol: {},
        
        inpSuratKontrolNoKa:"",
        TahunSuratKontrolNoKa:"",
        BulanSuratKontrolNoKa:"",
        FormatSuratKontrolNoKa:null,
        shwSuratKontrolNoKa: false,  
        dataSuratKontrolNoKa: {},

        
        inpDataKontrol: null,
        shwDataKontrol: false,  
        dataDataKontrol: {},
        dateRangeDataKontrol: {
            startDate: null,
            endDate: null,
        },

        datePickerConfig: {
            startDate: null,
            endDate: null,
            autoApply: true,
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },

        
        inpPoli:"",
        jenisPoli:null,
        tanggalPoli:null,
        shwPoli: false,  
        dataPoli: {},

        inpDokter:"",
        jenisDokter:null,
        tanggalDokter:null,
        shwDokter: false,  
        dataDokter: {},
    }
  },
  components:{
    DateRangePicker,Datepicker
  },
  methods: {
    searchNoSEP(){
        if(this.inpNoSEP){
            this.loadingOverlay = true
            let data = {
                type : "search-sep",
                search : this.inpNoSEP
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwNoSEP = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data SEP Tidak Ditemukan'
                    })
                }
                this.dataNoSEP = resp.response
                console.log(this.dataNoSEP)
                this.loadingOverlay = false
                this.shwNoSEP = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data SEP Tidak Ditemukan'
                })
            })
        }
    },
    resetNoSEP(){
        this.inpNoSEP = null
        this.shwNoSEP = false
        this.dataNoSEP = {}
    },
    
    searchSuratKontrol(){
        if(this.inpSuratKontrol){
            this.loadingOverlay = true
            let data = {
                type : "search-nomor-surat-kontrol",
                search : this.inpSuratKontrol
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwSuratKontrol = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data No Kontrol Tidak Ditemukan'
                    })
                }
                this.dataSuratKontrol = resp.response
                this.loadingOverlay = false
                this.shwSuratKontrol = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data No Kontrol Tidak Ditemukan'
                })
            })
        }
    },
    resetSuratKontrol(){
        this.inpSuratKontrol = null
        this.shwSuratKontrol = false
        this.dataSuratKontrol = {}
    },
    
    searchSuratKontrolNoKa(){
        if(this.inpSuratKontrolNoKa && this.TahunSuratKontrolNoKa && this.BulanSuratKontrolNoKa && this.FormatSuratKontrolNoKa){
            this.loadingOverlay = true
            let data = {
                type : "search-nomor-kartu",
                search : this.inpSuratKontrolNoKa,
                format : this.FormatSuratKontrolNoKa,
                tahun : this.TahunSuratKontrolNoKa,
                bulan : this.BulanSuratKontrolNoKa,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwSuratKontrolNoKa = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data No Kontrol Tidak Ditemukan'
                    })
                }
                this.dataSuratKontrolNoKa = resp.response
                this.loadingOverlay = false
                this.shwSuratKontrolNoKa = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data No Kontrol Tidak Ditemukan'
                })
            })
        }
    },
    resetSuratKontrolNoKa(){
        this.inpSuratKontrolNoKa = null
        this.shwSuratKontrolNoKa = false
        this.dataSuratKontrolNoKa = {}
    },

    searchDataKontrol(){
        if(this.inpDataKontrol && this.dateRangeDataKontrol.startDate && this.dateRangeDataKontrol.endDate){
            this.loadingOverlay = true
            let data = {
                type : "data-nomor-kontrol",
                search : this.inpDataKontrol,
                startDate: this.dateRangeDataKontrol.startDate,
                endDate: this.dateRangeDataKontrol.endDate,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwDataKontrol = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data No Kontrol Tidak Ditemukan'
                    })
                }
                this.dataDataKontrol = resp.response
                this.loadingOverlay = false
                this.shwDataKontrol = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data No Kontrol Tidak Ditemukan'
                })
            })
        }
    },
    resetDataKontrol(){
        this.inpDataKontrol = null
        this.shwDataKontrol = false
        this.dataDataKontrol = {}
    },

    
    searchPoli(){
        if(this.inpPoli && this.tanggalPoli && this.jenisPoli){
            this.loadingOverlay = true
            let data = {
                type : "search-data-poli",
                nomor : this.inpPoli,
                tanggal : this.tanggalPoli,
                jenis : this.jenisPoli,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwPoli = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Poli Tidak Ditemukan'
                    })
                }
                this.dataPoli = resp.response
                this.loadingOverlay = false
                this.shwPoli = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Poli Tidak Ditemukan'
                })
            })
        }
    },
    resetPoli(){
        this.inpPoli = null
        this.shwPoli = false
        this.dataPoli = {}
    },

    searchDokter(){
        if(this.inpDokter && this.tanggalDokter && this.jenisDokter){
            this.loadingOverlay = true
            let data = {
                type : "search-data-dokter",
                nomor : this.inpDokter,
                tanggal : this.tanggalDokter,
                jenis : this.jenisDokter,
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwDokter = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Dokter Tidak Ditemukan'
                    })
                }
                this.dataDokter = resp.response
                this.loadingOverlay = false
                this.shwDokter = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Dokter Tidak Ditemukan'
                })
            })
        }
    },
    resetDokter(){
        this.inpDokter = null
        this.shwDokter = false
        this.dataDokter = {}
    },
  },
  
    filters: {
        date(val) {
          return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
}

</script>

<style lang="scss" style="scoped">
    .vue-daterange-picker{
        width: 220px;
    }
</style>